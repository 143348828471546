import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Breadcrumb from '../sections/Orders/Breadcrumb';
import Favorder from '../sections/Orders/Favorder';
import ReportTable from '../sections/Orders/ReportTable';
import Quickbar from '../layouts/Quickbar';

class Report extends Component {

    componentDidMount() {
        this.checkSession();
    }
    

    checkSession = () => {
        let location=localStorage.getItem("location_id");
        if (Number(location) === 0) {
            window.location.href = '/frontdesklogin';
        }
    }

    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb pl-0">
                                        <li className="breadcrumb-item"><a href="/add-Users"><i className="material-icons">home</i> Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Users Report</li>
                                    </ol>
                                </nav>
                                <ReportTable/>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default Report;