
// import React, { useState } from "react";
// import Breadcrumb from './Breadcrumb';
// import Sidenavigation from "../../layouts/Sidenavigation";
// import Topnavigation from "../../layouts/Topnavigation";
// import Quickbar from "../../layouts/Quickbar";
// import axios from 'axios';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// function AddUsers() {
//     const [formData, setFormData] = useState({
//         user_name: '',
//         email: '',
//         phone_number: '',
//         dob: '',
//     });

//     const handleInputChange = (event) => {
//         const { name, value } = event.target;
//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         try {
//             const formDataObj = new FormData();
//             formDataObj.append('user_name', formData.user_name);
//             formDataObj.append('email', formData.email);
//             formDataObj.append('phone_number', formData.phone_number);
//             // formDataObj.append('dob',formData.dob);
//             formDataObj.append("dob", formData.dob ? formData.dob.toISOString().split("T")[0] : "");
//             let location = localStorage.getItem("location_id");
//             formDataObj.append('location_id', location);


//             await axios.post('https://apimenutwo.haikutheasiankitchen.com/index.php/Api/registration', formDataObj, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             window.location.href = '/report';
//         } catch (error) {
//             console.error('Error uploading file:', error);
//         }
//     };
//     const [startDate, setStartDate] = useState(null);
//     const [selectedDate, setSelectedDate] = useState(null);

//     return (
//         <>
//             <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
//                 <Sidenavigation />
//                 <main className="body-content">
//                     <Topnavigation />
//                     <div className="ms-content-wrapper">
//                         <div className="row">
//                             <div className="col-md-12" >
//                                 <Breadcrumb />
//                                 <div className="alert alert-success" role="alert" style={{ display: 'none' }}>
//                                     <strong>Well done!</strong> You successfully read this important alert message.
//                                 </div>
//                             </div>
//                             <div className="col-xl-12 col-md-12">
//                                 <div className="ms-panel ms-panel-fh">
//                                     <div className="ms-panel-header">
//                                         <h6>Add Guest</h6>
//                                     </div>
//                                     <div className="ms-panel-body">
//                                         <form className="needs-validation clearfix" key="addform" onSubmit={handleSubmit}>
//                                             <div className="form-row">
//                                                 <div className="col-md-12 mb-3">
//                                                     <label htmlFor="user_name">Name</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="user_name" placeholder="Enter Name" name="user_name" value={formData.user_name} onChange={handleInputChange} />
//                                                     </div>
//                                                     <label htmlFor="phone_number">Phone</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="phone_number" placeholder="Enter Phone Number" required name="phone_number" value={formData.phone_number} onChange={handleInputChange} />
//                                                     </div>
//                                                     <label htmlFor="email">Email</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="email" placeholder="Enter Email" name="email" value={formData.email} onChange={handleInputChange} />
//                                                     </div>
//                                                     <label htmlFor="email">Date Of Birth</label>
//                                                     <div className="input-group">
//                                                         {/* <DatePicker
//                                                             selected={startDate}
//                                                             onChange={(date) => setStartDate(date)}
//                                                             className="form-control w-100a"
//                                                             placeholderText="Select Date"
//                                                         /> */}
//                                                         {/* <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)}
//                                                             dateFormat="dd/MM/yyyy" isClearable placeholderText="Choose a date" showYearDropdown 
//                                                         /> */}
//                                                         <DatePicker
//         selected={formData.dob}
//         // value={formData.dob}
//         onChange={(date) => setSelectedDate(date)}
//         dateFormat="dd/MM/yyyy"
//         isClearable
//         placeholderText="Choose a date"
//         showYearDropdown // Enables year dropdown
//         scrollableYearDropdown // Allows scrolling for years
//         yearDropdownItemNumber={50} // Adjust the number of years to show
//         showMonthDropdown // Enables month dropdown
//       />
//                                                     </div>

//                                                 </div>
//                                                 <div className="ms-panel-header new">
//                                                     <button className="btn btn-primary d-block" type="submit">Save</button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </main>
//                 <Quickbar />
//             </div>
//         </>
//     );
// }

// export default AddUsers;




import React, { useState } from "react";
import Breadcrumb from "./Breadcrumb";
import Sidenavigation from "../../layouts/Sidenavigation";
import Topnavigation from "../../layouts/Topnavigation";
import Quickbar from "../../layouts/Quickbar";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddUsers() {
    const [formData, setFormData] = useState({
        user_name: "",
        email: "",
        phone_number: "",
        dob: null, // Ensure it's a date type
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle date selection
    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            dob: date,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formDataObj = new FormData();
            formDataObj.append("user_name", formData.user_name);
            formDataObj.append("email", formData.email);
            formDataObj.append("phone_number", formData.phone_number);
            formDataObj.append("dob", formData.dob ? formData.dob.toISOString().split("T")[0] : ""); // Convert to YYYY-MM-DD format
            let location = localStorage.getItem("location_id");
            formDataObj.append("location_id", location);

            await axios.post(
                "https://apimenutwo.haikutheasiankitchen.com/index.php/Api/registration",
                formDataObj,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            window.location.href = "/report";
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <>
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Breadcrumb />
                                <div
                                    className="alert alert-success"
                                    role="alert"
                                    style={{ display: "none" }}
                                >
                                    <strong>Well done!</strong> You successfully read this important alert message.
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Add Guest</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <form className="needs-validation clearfix" key="addform" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="user_name">Name</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="user_name"
                                                            placeholder="Enter Name"
                                                            name="user_name"
                                                            value={formData.user_name}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label htmlFor="phone_number">Phone</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="phone_number"
                                                            placeholder="Enter Phone Number"
                                                            required
                                                            name="phone_number"
                                                            value={formData.phone_number}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label htmlFor="email">Email</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="email"
                                                            placeholder="Enter Email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label htmlFor="dob">Date Of Birth</label>
                                                    <div className="input-group">
                                                        <DatePicker
                                                            selected={formData.dob}
                                                            onChange={handleDateChange}
                                                            dateFormat="dd/MM/yyyy"
                                                            isClearable
                                                            placeholderText="Choose a date"
                                                            showYearDropdown // Enables year dropdown
                                                            scrollableYearDropdown // Allows scrolling for years
                                                            yearDropdownItemNumber={50} // Adjust the number of years to show
                                                            showMonthDropdown // Enables month dropdown
                                                            className="form-control w-100"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-panel-header new">
                                                    <button className="btn btn-primary d-block" type="submit">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        </>
    );
}

export default AddUsers;
