import React, {  useState } from "react";
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { Modal } from "react-bootstrap";


function Popup() {

    const [show11, setShow11] = useState(true);

    // const handleShow11=()=> {
    //     setShow11(true);
    // }

    const handleClose=()=> {
        //setShow11(false);
    }

    const [formData, setFormData] = useState({
        user_name: '',
        email: '',
        phone_number: '',

      });

    const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
        ...formData,
        [name]: value,
    });
    };


    const handleSubmit = async(event) => {
        event.preventDefault();

        try {
            const formDataObj = new FormData();
            formDataObj.append('user_name', formData.user_name);
            formDataObj.append('email', formData.email);
            formDataObj.append('phone_number', formData.phone_number);
      
            const response = await axios.post('https://apimenutwo.haikutheasiankitchen.com/index.php/Api/registration', formDataObj, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            //window.location.href = '/menu';
            //console.log('File upload response:', response.data);
          } catch (error) {
            console.error('Error uploading file:', error);
          }
          window.location.href = '/menu';

      };

    const hanldleskip=()=>{
        window.location.href = '/menu';
    }
      
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
              
               

                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6>Modals Style 3</h6>
                            </div>
                            <div className="ms-panel-body">
                                <div className="row">
                                   
                                    <Modal className="modal-min" show={show11} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                                        centered>
                                        <Modal.Body className="text-center">
                                            {/* <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button> */}
                                        
                                            <form onSubmit={handleSubmit}>
                                                <input type="text" name="user_name" value={formData.user_name} onChange={handleInputChange} placeholder="Name" />
                                                <input
                                                    type="number"
                                                    maxLength={10}
                                                    name="phone_number" value={formData.phone_number} onChange={handleInputChange}
                                                    placeholder="Phone"
                                                />
                                                <input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
                                                 {/* <select>
                                                    <option>Select Branch</option>

                                                    <option>Gachibowli</option>
                                                    <option>Banjara Hills</option>
                                                    <option>Kokapet</option>
                                                 </select> */}
                                                <div className='submit-skip'>
                                                <button type="submit" name="submit">
                                                  Submit
                                                </button>
                                                <button type="button" name="skip" onClick={hanldleskip} >
                                                    Skip
                                                </button>
                                                </div>
                                                </form>
                                        </Modal.Body>
                                    </Modal>
                                    
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    
}

export default Popup;