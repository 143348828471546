// import React, { Component, useState, useEffect } from 'react';
// import "rc-pagination/assets/index.css";
// import '../../../DataTable.css';
// import { Search } from 'react-feather';
// import  { useRef } from "react";


// function ReportTable() {
//   const itemsPerPage = 10;
//   const [data, setAllData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const totalPages = Math.ceil(data.length / itemsPerPage);
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const currentData = data.slice(startIndex, endIndex);


//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };


//   useEffect(() => {
//     loadcategory();
//   }, []);



//   const loadcategory = () => {
//     fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/users", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "multipart/form-data",

//       },
//     })
//       .then((res) => res.json())
//       .then((result) => {

//         if (result.data != "") {
//           const data = result.data;
//           setAllData(data);
//         }
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });
//   }

//   const renderPageNumbers = () => {
//     const pages = [];
//     let startPage, endPage;
//     if (totalPages <= 7) {
//       startPage = 1;
//       endPage = totalPages;
//     } else {
//       if (currentPage <= 4) {
//         startPage = 1;
//         endPage = 7;
//       } else if (currentPage + 3 >= totalPages) {
//         startPage = totalPages - 6;
//         endPage = totalPages;
//       } else {
//         startPage = currentPage - 3;
//         endPage = currentPage + 3;
//       }
//     }
//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(
//         <button
//           className={`paginationCategory ${currentPage === i ? 'active' : ''}`}
//           key={i}
//           onClick={() => handlePageChange(i)}
//         >
//           {i}
//         </button>
//       );
//     }
//     return pages;
//   };


//   return (
//     <>
//       <style>{`
//             @media (max-width: 768px) {
//               .table-responsive1 {
//                 display: flex;
//                 width: 100%;
//                 -webkit-overflow-scrolling: touch;
//                 overflow-x: auto;
//                 flex-wrap: wrap;
//               }
//             }
//             .paginationCategory {
//               margin: 0 5px;
//             }
//             .paginationCategory.active {
//               font-weight: bold;
//             }
//           `}</style>
//       <div className="col-12">
//         <div className="ms-panel">
//           <div className="ms-panel-header">

//             <div class="container">
//               <div class="row">
//                 <div class="col-md-4">
//                   <h6>Users Report</h6>
//                 </div>
//                 <div className='col-md-4'>
//                   <div className="searchbar">
//                     <input type="text" placeholder="Search..." className="searchbar-input" />
//                     <button type="submit" className="searchbar-button">
//                       <Search size={20} />
//                     </button>
//                   </div>
//                 </div>
//                 <div class="col-md-2">
//                   <a href="/add-Users">
//                     <button class="addcategory" fdprocessedid="k3s5zg">Add User</button>
//                   </a>
//                 </div>
//                 <div class="col-md-2">
//                   <button class="addcategory" fdprocessedid="k3s5zg">Export</button>

//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="ms-panel-body">
//             <div className="table-responsive1">

//               <table className="table table-hover thead-primary" >
//                 <thead>
//                   <tr >
//                     <td >User Name</td>
//                     <td >Email</td>
//                     <td >Phone Number</td>
//                     <td >Date</td>
//                   </tr>
//                 </thead>
//                 {currentData.map((data, i) => (
//                   <tr key={i}>
//                     <td >{data.user_name}</td>
//                     <td >{data.email}</td>
//                     <td >{data.phone_number}</td>
//                     <td >{data.date}</td>
//                   </tr>
//                 ))}
//               </table>

//               <div className="pagination">
//                 <button className='addcategory'
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 >
//                   Previous
//                 </button>
//                 {renderPageNumbers()}
//                 <button className='addcategory'
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 >
//                   Next
//                 </button>
//               </div>

//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );

// }

// export default ReportTable;


// import React, { useState, useEffect } from 'react';
// import "rc-pagination/assets/index.css";
// import '../../../DataTable.css';
// import { Search } from 'react-feather';

// function ReportTable() {
//   const itemsPerPage = 10;
//   const [data, setAllData] = useState([]); // Ensuring data is initialized as an empty array
//   const [currentPage, setCurrentPage] = useState(1);

//   const totalPages = Math.ceil(data.length / itemsPerPage);
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const currentData = data.slice(startIndex, endIndex);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   useEffect(() => {
//     loadcategory();
//   }, []);

//   const loadcategory = async () => {
//     try {
//       const response = await fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/users", {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       const result = await response.json();

//       console.log("API Response:", result); // Debugging step

//       if (result && result.data) {
//         setAllData(result.data); // Ensure 'data' is correctly set
//       } else {
//         setAllData([]); // Set empty array if no data found
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setAllData([]); // Prevent undefined errors
//     }
//   };

//   const renderPageNumbers = () => {
//     const pages = [];
//     let startPage, endPage;

//     if (totalPages <= 7) {
//       startPage = 1;
//       endPage = totalPages;
//     } else {
//       if (currentPage <= 4) {
//         startPage = 1;
//         endPage = 7;
//       } else if (currentPage + 3 >= totalPages) {
//         startPage = totalPages - 6;
//         endPage = totalPages;
//       } else {
//         startPage = currentPage - 3;
//         endPage = currentPage + 3;
//       }
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(
//         <button
//           className={`paginationCategory ${currentPage === i ? 'active' : ''}`}
//           key={i}
//           onClick={() => handlePageChange(i)}
//         >
//           {i}
//         </button>
//       );
//     }
//     return pages;
//   };

//   return (
//     <>
//       <style>{`
//             @media (max-width: 768px) {
//               .table-responsive1 {
//                 display: flex;
//                 width: 100%;
//                 -webkit-overflow-scrolling: touch;
//                 overflow-x: auto;
//                 flex-wrap: wrap;
//               }
//             }
//             .paginationCategory {
//               margin: 0 5px;
//             }
//             .paginationCategory.active {
//               font-weight: bold;
//             }
//           `}</style>
//       <div className="col-12">
//         <div className="ms-panel">
//           <div className="ms-panel-header">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-4">
//                   <h6>Users Report</h6>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="searchbar">
//                     <input type="text" placeholder="Search..." className="searchbar-input" />
//                     <button type="submit" className="searchbar-button">
//                       <Search size={20} />
//                     </button>
//                   </div>
//                 </div>
//                 <div className="col-md-2">
//                   <a href="/add-Users">
//                     <button className="addcategory" fdprocessedid="k3s5zg">Add User</button>
//                   </a>
//                 </div>
//                 <div className="col-md-2">
//                   <button className="addcategory" fdprocessedid="k3s5zg">Export</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="ms-panel-body">
//             <div className="table-responsive1">
//               <table className="table table-hover thead-primary">
//                 <thead>
//                   <tr>
//                     <td>User Name</td>
//                     <td>Email</td>
//                     <td>Phone Number</td>
//                     <td>Date</td>
//                   </tr>
//                 </thead>
//                 {currentData && currentData.length > 0 ? (
//                   currentData.map((data, i) => (
//                     <tr key={i}>
//                       <td>{data.user_name}</td>
//                       <td>{data.email}</td>
//                       <td>{data.phone_number}</td>
//                       <td>{data.date}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="4">No data available</td>
//                   </tr>
//                 )}
//               </table>

//               <div className="pagination">
//                 <button className="addcategory"
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 >
//                   Previous
//                 </button>
//                 {renderPageNumbers()}
//                 <button className="addcategory"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 >
//                   Next
//                 </button>
//               </div>

//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ReportTable;



import React, { useState, useEffect } from 'react';
import "rc-pagination/assets/index.css";
import '../../../DataTable.css';
import { Search } from 'react-feather';

import * as XLSX from 'xlsx'; // Import XLSX for exporting to Excel

function ReportTable() {
  const itemsPerPage = 10;
  const [data, setAllData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  // const totalPages = Math.ceil(data.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentData = data.slice(startIndex, endIndex);


  useEffect(() => {
    loadcategory();
  }, []);

  const loadcategory = async () => {
    try {
      const response = await fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/users", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      const result = await response.json();
      if (result && result.data) {
        setAllData(result.data);
      } else {
        setAllData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAllData([]);
    }
  };


  const handleExport = () => {
    if (filteredTableData.length === 0) {
      alert("No data available to export.");
      return;
    }
    const formattedData = filteredTableData.map(item => ({
      "User Name": item.user_name,
      "Email": item.email,
      "Phone Number": item.phone_number,
      "Location": item.location,
      "Date": item.date,
      "Time":item.time
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Users Report");
    XLSX.writeFile(workbook, "Filtered_Users_Report.xlsx");
  };



  const renderPageNumbers = () => {
    const pages = [];
    let startPage, endPage;

    if (totalPages <= 7) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 4) {
        startPage = 1;
        endPage = 7;
      } else if (currentPage + 3 >= totalPages) {
        startPage = totalPages - 6;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 3;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          className={`paginationCategory ${currentPage === i ? 'active' : ''}`}
          key={i}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };
  const filteredTableData = data.filter((item) =>
    (item.user_name ? item.user_name.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
    (item.email ? item.email.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
    (item.name ? item.name.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
    (item.phone_number ? String(item.phone_number).toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
    (item.location ? item.location.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
    (item.date ? item.date.toLowerCase() : "").includes(searchTerm.toLowerCase())||
    (item.time ? item.time.toLowerCase() : "").includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredTableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredTableData.slice(startIndex, endIndex);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  return (
    <>
      <style>{`
            @media (max-width: 768px) {
              .table-responsive1 {
                display: flex;
                width: 100%;
                -webkit-overflow-scrolling: touch;
                overflow-x: auto;
                flex-wrap: wrap;
              }
            }
            .paginationCategory {
              margin: 0 5px;
            }
            .paginationCategory.active {
              font-weight: bold;
            }
          `}</style>
      <div className="col-12">
        <div className="ms-panel">
          <div className="ms-panel-header">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h6 className='users-heading'>Guest Report</h6>
                </div>
                <div className="col-md-4">
                  <div className="searchbar">
                    <input
                      className='searchbar-input'
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    {/* <input type="text" placeholder="Search..." className="searchbar-input" /> */}
                    <button type="submit" className="searchbar-button">
                      <Search size={20} />
                    </button>
                  </div>
                </div>
                <div className="col-md-2 add-export-btn">
                  <a href="/add-Users">
                    <button className="addcategory" fdprocessedid="k3s5zg">Add Guest</button>
                  </a>
                </div>
                <div className="col-md-2 add-export-btn">
                  <button className="addcategory" onClick={handleExport}>Export</button>
                </div>

                <div className='add-buttons mobile'>
                  <div className="col-md-2">
                    <a href="/add-Users">
                      <button className="addcategory" fdprocessedid="k3s5zg">Add Guest</button>
                    </a>
                  </div>
                  <div className="col-md-2">
                    <button className="addcategory" onClick={handleExport}>Export</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ms-panel-body">
            <div className="table-responsive1">
              <table className="table table-hover thead-primary">
                <thead>
                  <tr>
                    <td>Guest Name</td>
                    <td>Email</td>
                    <td>Location</td>
                    <td>Phone Number</td>
                    <td>Date Of Birth</td>
                    <td>Date</td>
                    <td>Time</td>
                    
                  </tr>
                </thead>
                <tbody>
                  {currentData && currentData.length > 0 ? (
                    currentData.map((data, i) => (
                      <tr key={i}>
                        <td>{data.user_name}</td>
                        <td>{data.email}</td>
                        <td>{data.name}</td>
                        <td>{data.phone_number}</td>
                        <td>{data.dob}</td>
                        <td>{data.date}</td>
                        <td>{data.time}</td>
                        
                      </tr>
                    ))

                  ) : (
                    <tr>
                      <td colSpan="5">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="pagination">
                <button className="addcategory"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {renderPageNumbers()}
                <button className="addcategory"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportTable;

