import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class Loginpage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show1: false, 
            username: '',
            password: '',
            location: '',
            locations: [],  // Store API-fetched locations here
            error: ''
        };
    }

    // Fetch locations when component mounts
    componentDidMount() {
        this.fetchLocations();
    }

    fetchLocations = () => {
        fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/locations", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
        })
        .then((res) => res.json())
        .then((result) => {
            if (result.data) {
                this.setState({ locations: result.data });
            }
        })
        .catch((err) => {
            console.error("Error fetching locations:", err);
        });
    };

    handleShow1 = () => {
        this.setState({ show1: true });
    };

    handleClose = () => {
        this.setState({ show1: false });
    };

    handleLogin = async () => {
        const { username, password, location } = this.state;

        if (!username || !password) {
            this.setState({ error: "Please enter username and password" });
            return;
        }

        if (!location) {
            this.setState({ error: "Please select a location" });
            return;
        }

        try {
            const response = await fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/login", {
                method: "POST",
                body: JSON.stringify({
                    user_name: username,
                    password: password,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
            });

            const result = await response.json();

            if (result.data && result.data.id) {
                localStorage.setItem("token", result.data.id);
                localStorage.setItem("location_id", location);

                window.location.href = "/menu-grid";
            } else {
                this.setState({ error: "Invalid username or password" });
            }
        } catch (err) {
            console.error("Login Error:", err);
            this.setState({ error: "Something went wrong. Please try again later." });
        }
    };

    render() {
        const { username, password, location, locations, error } = this.state;

        return (
            <div className="ms-content-wrapper ms-auth">
                <div className="ms-auth-container">
                    <div className="ms-auth-col">
                        <div className="ms-auth-bg" />
                    </div>
                    <div className="ms-auth-col">
                        <div className="ms-auth-form">
                            <form className="needs-validation" noValidate>
                                <h3>Login</h3>
                                <p>Please enter your User ID and password to continue</p>

                                <div className="mb-3">
                                    <label htmlFor="username">User ID</label>
                                    <div className="input-group">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="username" 
                                            value={username} 
                                            placeholder="Email User ID" 
                                            required 
                                            onChange={(e) => this.setState({ username: e.target.value })} 
                                        />
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-group">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="password" 
                                            value={password} 
                                            placeholder="Password" 
                                            required 
                                            onChange={(e) => this.setState({ password: e.target.value })} 
                                        />
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="location">Location</label>
                                    <select 
                                        className="form-control" 
                                        value={location} 
                                        onChange={(e) => this.setState({ location: e.target.value })}
                                    >
                                        <option value="">Select Branch</option>
                                        {locations.map((loc) => (
                                            <option key={loc.id} value={loc.id}>
                                                {loc.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>

                                <button 
                                    className="btn btn-primary mt-4 d-block w-100" 
                                    type="button" 
                                    onClick={this.handleLogin}
                                >
                                    Sign In
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal 
                    className="modal-min" 
                    show={this.state.show1} 
                    onHide={this.handleClose} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body className="text-center">
                        <button type="button" className="close" onClick={this.handleClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <i className="flaticon-secure-shield d-block" />
                        <h1>Forgot Password?</h1>
                        <p>Enter your email to recover your password</p>
                        <form method="post">
                            <div className="ms-form-group has-icon">
                                <input type="text" placeholder="Email Address" className="form-control" name="forgot-password" /> 
                                <i className="material-icons">email</i>
                            </div>
                            <button type="submit" className="btn btn-primary shadow-none">Reset Password</button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Loginpage;
