import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

// import logo from '../../assets/img/foodtech/foodtech-logo-216x62.png';

class Sidenavigation extends Component {

    removeoverlay = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }
    componentDidMount() {
        function setActiveMenuItem() {
            $('.ms-main-aside .menu-item>a').on('click', function () {
                $(this).removeAttr('href');
                var element = $(this).parent('li');
                if (element.hasClass('active')) {
                    element.removeClass('active');
                    element.find('li').removeClass('active');
                    element.find('.collapse').slideUp();
                } else {
                    element.addClass('active');
                    element.children('.collapse').slideDown();
                    element.siblings('li').children('.collapse').slideUp();
                    element.siblings('li').removeClass('active');
                    element.siblings('li').find('li').removeClass('active');
                    element.siblings('li').find('.collapse').slideUp();
                }
            });
        }
        setActiveMenuItem();
    }



    render() {
        const user = localStorage.getItem("token"); // Retrieve user token
        return (
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={this.removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
                    {/* Logo */}
                    <div className="logo-sn ms-d-block-lg">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            {/* <img src={logo} alt="logo" /> */}<h3>Haiku</h3>
                        </Link>
                    </div>
                    {/* Navigation */}
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        {parseInt(user) === 1 ? (
                            <>
                        
                     
                        <li className="menu-item">
                            <Link to="/menu-grid"> <span><i className="fas fa-archive  fs-16" />Menu</span>
                            </Link>
                        </li >
                        <li className="menu-item">
                            <Link to="/add-product"> <span><i className="fas fa-shopping-cart  fs-16" />Add Product</span>
                            </Link>
                        </li >

                        <li className="menu-item">
                            <Link to="/category"> <span><i className="fas fa-clipboard-list fs-16" />Category</span>
                            </Link>
                        </li >
                        <li className="menu-item">
                            <Link to="/category-order"> <span><i className="fas fa-clipboard-list fs-16" />Category Order</span>
                            </Link>
                        </li >
                        <li className="menu-item">
                            <Link to="/importfile"> <span><i className="fas fa-file fs-16" />Import File</span>
                            </Link>
                        </li >
                        <li className="menu-item">
                            <Link to="/updatelogo"> <span><i className="fas fa-image fs-16" />Update Theme Details</span>
                            </Link>
                        </li >
                        <li className="menu-item">
                            <Link to="/report"> <span><i className="fas fa-users fs-16" />Guest Report</span>
                            </Link>
                        </li >
                        <li className="menu-item">
                            <Link to="/user-feedback"> <span><i className="fas fa-clipboard-list fs-16" />Feedback</span>
                            </Link>
                        </li >
                        </>
                        ) : (
                        <li className="menu-item">
                        <Link to="/report"> <span><i className="fas fa-users fs-16" />Guest Report</span>
                        </Link>
                    </li >
                     )}
                        {/* /Apps */}
                    </ul >
                </Scrollbar >
            </div >
        );
    }
}

export default Sidenavigation;